<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '438457271247244');
fbq('track', 'PageView');
export let name;
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=438457271247244&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->

<style>
  main {
    padding: 0 1.5em;
    max-width: 1400px;
    margin: -1em auto;
  }

  h5 {
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
  }

  h5:before {
    content: "";
    position: absolute;
    background: rgb(96, 125, 139);
    height: 1px;
    width: 20px;
    bottom: -4px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 65vh;
  }

  a {
    font-family: "EB Garamond", serif;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.8);
    font-size: 28px;
    margin-bottom: 16px;
  }

  a:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
  }

  svg {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    width: 50px;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<main>
  <nav>
    <h5>{name}</h5>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 296.11 240.97">
      <linearGradient id="firstGradient" x="100%" y="100%">
        <stop offset="0" stop-color="yellow">
          <animate
            attributeName="stop-color"
            values="green;teal;purple;orange;red"
            dur="20s"
            from="0"
            to="100%"
            repeatCount="indefinite" />
        </stop>
        <stop offset="100" stop-color="purple">
          <animate
            attributeName="stop-color"
            values="lightblue;blue;red;red;black;red;red;purple;lightblue"
            dur="20s"
            from="0%"
            to="100%"
            repeatCount="indefinite" />

        </stop>

      </linearGradient>

      <g style="opacity: 0.38" stroke="url(#firstGradient)">
        <polyline
          points="159.87 60.33 175.27 33.66 294.38 239.97 56.16 239.97 71.38
          213.6"
          style="fill: none;stroke-miterlimit: 10;stroke-width: 2px" />
        <polyline
          points="62.28 208.31 1.73 208.31 120.84 2 239.96 208.31 62.28 208.31"
          style="fill: none;stroke-miterlimit: 10;stroke-width: 2px" />
      </g>
    </svg>
  </nav>
  <div class="wrapper">
    <a href="mailto:hello@albertmejia.com">email</a>
    <a href="https://www.linkedin.com/in/albertmejia" target="_blank">
      linkedin
    </a>
  </div>
</main>
